export function getRandomStringFromList(stringList?: string[]): string {
  let randomObject = "";
  if (stringList) {
    if (stringList.length === 1) {
      randomObject = stringList[0];
    }
    if (stringList.length > 1) {
      const randomIndex = Math.floor((Math.random() * 100 * stringList.length) / 100);
      randomObject = stringList[randomIndex];
    }
  }
  return randomObject;
}
