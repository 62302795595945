import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import { getRandomStringFromList } from "../../../common/helpers/list-helper";
import { ContentfulCryptoExchange } from "../../../common/types/contentful-models";
import SummaryCard from "../Instragram/summary-card";

type Props = {
  allContentfulCryptoExchange: {
    nodes: ContentfulCryptoExchange[];
  };
};

const filterExchange = ['bsdex', 'NURI', 'Bitpanda PRO']

const Exchanges: React.FC = () => {
  const data = useStaticQuery<Props>(graphql`
    query ExchangesStaticQuery {
      allContentfulCryptoExchange {
        nodes {
          name
          registerBonus
          exchangeFeatures
          complexity
          numberTradableCoins
          feeBuyCryptoInPercent
          feeSellCryptoInPercent
          surchargeInPercent
          registerBonusInEuro
          countryUnicode
          companyLogo {
            id
            gatsbyImageData(height: 32)
          }
          referralLinks
        }
      }
    }
  `);

  const cryptoExchanges = data?.allContentfulCryptoExchange?.nodes
    .filter((exchange) => !!exchange.name)
    .filter(exchange => !filterExchange.includes(exchange.name));

  return (
    <div className="grid gap-3 mt-5">
      <h2 className="text-lg">Börsen bei denen du Kryptowährungen kaufen kannst</h2>
      {cryptoExchanges.map((exchange) => (
        <>
          <SummaryCard
            key={exchange.name}
            image={<GatsbyImage image={getImage(exchange.companyLogo)} alt={exchange.name} />}
            title={`${exchange.name}*`}
            description={`Erhalte einen Anmeldebonus von ${exchange.registerBonus}*`}
            href={getRandomStringFromList(exchange.referralLinks) }
          >
          </SummaryCard>
        </>
      ))}
    </div>
  );
};

export default Exchanges;
