import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Exchanges from "../components/Modules/support/exchanges";
import OtherBenefits from "../components/Modules/support/others";
import Title from "@components/UI-Elements/Typography/Title";

const Support: React.FC = () => {
  return (
    <Layout>
      <SEO keywords={['unterstütze', 'uns']} title="Unterstütze uns" />
      <Title className="mb-5">Unterstütze uns</Title>

      <Exchanges/>
      <OtherBenefits/>
    </Layout>
  );
};

export default Support;
