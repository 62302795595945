import React from "react";
import SummaryCard from "../Instragram/summary-card";

const OtherBenefits: React.FC = () => {
  return (
    <div className="grid gap-3 mt-5">
      <h2 className="text-lg">Wie ich Cashback auf meine täglichen Ausgaben erhalte:</h2>
      <SummaryCard
        title={"Crypto.com*"}
        description={"Erhalte bis zu 8% Cashback auf deine täglichen Einkäufe. Der Cashback wird in CRO ausbezahlt."}
        href={"https://crypto.com/app/byjhe4vd6c"}
      />
      <SummaryCard
        title={"Vivid*"}
        description={"Vivid hat eig. nichts mit Kryptowährungen zu tun. Es gibt hier aber einen attraktiven Willkommensbonus von 30€ und nochmal bis zu 100€ als Cashback."}
        href={"https://vivid.money/r/mathias9MH"}
      />
      <h2 className="text-lg">Weitere Anbieter, die ich nutze:</h2>
      <SummaryCard
        title={"Pocket*"}
        description={"Kaufe sicher per Dauerauftrag Bitcoins, die direkt an deine persönliche Wallet geschickt werden."}
        href={"https://pocketbitcoin.com/de?ref=einfachcrypto"}
      />
      <SummaryCard
        title={"CoinTracking.info*"}
        description={"Behalte dein Portfolio im Blick, um Steuerfreie Coins zu identifizieren und einen Steuerreport für das Finanzamt zu erstellen."}
        href={"https://cointracking.info?ref=M115476"}
      />
      <p className="text-xs">* Bei den mit einem Stern* gekennzeichneten Links handelt es sich um so genannte Weiterempfehlungslinks. Das heißt nachdem du dich angemeldet hast, erhalten wir beide eine kleine Belohnung.</p>
    </div>
  );
};

export default OtherBenefits;
