import * as React from "react";
import Link from "../../UI-Elements/Navigation/Link";

type Prop = {
  image?: React.ReactNode;
  title: string;
  description?: string;
  href?: string;
};

const SummaryCard: React.FC<Prop> = ({ title, description, href, image, children }) => {
  return (
    <Link href={href} className="text-white text-xs" external>
      <div className="grid grid-cols-12 gap-2 rounded-md bg-einfach-crypto-primary p-4 flex items-center">
        <div className="col-span-10 flex items-left flex-col">
          <h3 className="text-xl">{title}</h3>
          <span>{description}</span>
          {children}
        </div>
        {image && <div className="col-span-2 flex items-center">{image}</div>}
      </div>
    </Link>
  );
};

export default SummaryCard;
